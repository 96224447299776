import { createRouter, createWebHashHistory } from 'vue-router';


let newRouter = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/components/HomeView.vue'),
            children: [
                // 子路由
                {
                    path: '',
                    name: 'main',
                    component: () => import('@/components/ChatMain.vue'),
                },
                {
                    path: '/about',
                    name: 'about',
                    component: () => import('@/components/AboutUs.vue'),

                }, {
                    path: '/contact',
                    name: 'contacts',
                    component: () => import('@/components/ContactUs.vue'),
                }, {
                    path: '/moreModels',
                    name: 'moreModels',
                    component: () => import('@/components/ModelMore.vue'),

                }, {
                    path: '/talk',
                    name: 'talk',
                    component: () => import('@/components/ChatTalk.vue'),

                }, {
                    path: '/painting',
                    name: 'painting',
                    component: () => import('@/components/AIPainting.vue')
                }
            ]

        },
        {
            path: '/wxcode',
            name: 'wxcode',
            component: () => import('@/components/WxCode.vue')
        }

    ]
})


export default newRouter;