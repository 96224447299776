import { resolveComponent as _resolveComponent, createVNode as _createVNode, KeepAlive as _KeepAlive, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_KeepAlive, null, [_createVNode(_component_router_view)], 1024))]);
}