import { createApp } from 'vue'
import App from './App.vue'

import router from './router/index.js';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
import persist from 'pinia-plugin-persist';

const pinia = createPinia()
pinia.use(persist)

const errorHandler = (err, vm, info) => {
    console.log(err, vm, info)
}



const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.errorHandler = errorHandler;
app.use(pinia)
app.use(router);
app.config.warnHandler = () => null
app.mount('#app')
